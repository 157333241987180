<template>
  <div>
    <sdPageHeader v-if="SaleId" :title="'Edit ' + saleLabel">
      <template v-slot:buttons>
        <div class="page-header-actions">
          <sdPopover placement="bottomLeft" action="click">
            <template v-slot:content>
              <a to="#" v-if="params.type != 2" @click="onChangeSaleType(2)">
                <sdFeatherIcons type="book-open" />
                <span>Order</span>
              </a>
              <a to="#" v-if="params.type != 3" @click="onChangeSaleType(3)">
                <sdFeatherIcons type="file-text" />
                <span>Invoice</span>
              </a>
            </template>
            <sdButton size="small" type="primary">
              <sdFeatherIcons type="share" />
              <span>Save as</span>
            </sdButton>
          </sdPopover>
          <!-- <sdShareButton /> -->
          <!-- <sdButton size="small" type="secondary" @click="generatePdf()">
            <sdFeatherIcons type="download" />
            Export PDF
          </sdButton> -->
        </div>
      </template>
    </sdPageHeader>
    <sdPageHeader v-else :title="'Add new ' + saleLabel"> </sdPageHeader>
    <Main>
      <a-form
        class="main-form"
        name="sDash_validation-form"
        ref="formRef"
        :rules="rules"
        @finish="onSubmit"
        :model="formState"
        :layout="formState.layout"
      >
        <a-row :gutter="25">
          <a-col :xs="24">
            <sdCards :title="saleLabel + ' details'">
              <FormValidationWrap>
                <HorizontalFormStyleWrap>
                  <a-row :gutter="30">
                    <a-col :span="6" :xs="6">
                      <a-form-item name="salesDate" label="Date">
                        <a-date-picker
                          :disabled="SaleApiloading"
                          v-model:value="formState.salesDate"
                          :style="{ width: '100%' }"
                          :format="dateFormat"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="6" :xs="6">
                      <a-form-item name="number" label="Number">
                        <a-input
                          class="custom"
                          :disabled="SaleApiloading"
                          v-model:value="formState.number"
                          :placeholder="saleLabel + ' Number'"
                          :style="{ width: '100%' }"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="6" :xs="6">
                      <a-form-item label="Partner" name="partenaireId">
                        <a-select
                          v-if="partnerList.length"
                          :disabled="SaleApiloading"
                          v-model:value="formState.partenaireId"
                          placeholder="Please choose Owner"
                          show-search
                          style="width: 100%"
                          :options="partnerData"
                          :not-found-content="
                            partnerData.length ? undefined : null
                          "
                          :filter-option="false"
                          @search="searchPartner"
                        >
                          <template v-if="partnerData.length" #notFoundContent>
                            <a-spin size="small" />
                          </template>
                        </a-select>
                        <a-select
                          v-else
                          :disabled="SaleApiloading"
                          v-model:value="formState.partenaireId"
                          placeholder="Please choose Owner"
                        >
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :span="6" :xs="6">
                      <a-form-item label="Status" name="saleStatus">
                        <a-select v-model:value="formState.saleStatus">
                          <a-select-option :value="1"> Draft</a-select-option>
                          <a-select-option :value="2">
                            Confirmed</a-select-option
                          >
                          <a-select-option :value="3">
                            Canceled</a-select-option
                          >
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :xs="12" :md="12">
                      <a-form-item label="Note">
                        <a-textarea
                          v-model:value="formState.note"
                          :style="{ width: '100%' }"
                          placeholder="Note ..."
                          name="address"
                          class="sDash_unresizable"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :xs="12" :md="12">
                      <a-form-item label="Private Note">
                        <a-textarea
                          v-model:value="formState.privateNote"
                          :style="{ width: '100%' }"
                          placeholder="Private Note ..."
                          name="address"
                          class="sDash_unresizable"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="6" :offset="18" class="button-container">
                      <sdButton
                        htmlType="onSubmit"
                        key="onSubmit"
                        class="btn-outlined ant-btn-lg client-form-submit"
                        outlined
                        type="primary"
                      >
                        <span>Save</span>
                      </sdButton>
                    </a-col>
                  </a-row>
                </HorizontalFormStyleWrap>
              </FormValidationWrap>
            </sdCards>
          </a-col>
        </a-row>
      </a-form>
      <a-row :gutter="25">
        <a-col :xs="24">
          <sdCards title="Products" caption="Products">
            <template #button>
              <sdButton @click="showAddProductModal" type="primary">
                <sdFeatherIcons type="plus" size="14" />
                Add New
              </sdButton>
            </template>
            <a-table :columns="columns" :dataSource="productsData" bordered>
              <template
                v-for="col in ['price', 'quantity']"
                #[col]="{ text, record }"
                :key="col"
              >
                <div>
                  <a-input
                    v-if="editableData[record.key]"
                    v-model:value="editableData[record.key][col]"
                    style="margin: -5px 0"
                  />
                  <template v-else>
                    {{ text }}
                  </template>
                </div>
              </template>
              <template #total="{ record }">
                <div>
                  {{ record.quantity * record.price }} {{ record.currency }}
                </div>
              </template>
              <template #operation="{ record }">
                <div class="editable-row-operations">
                  <span v-if="editableData[record.key]">
                    <a @click="save(record.key)">Save</a>
                    <a @click="cancel(record.key)">Cancel</a>
                  </span>
                  <span v-else>
                    <a @click="edit(record.key)">Edit</a>
                    <a @click="deleteFromSale(record)">Delete</a>
                  </span>
                </div>
              </template>
            </a-table>
          </sdCards>
        </a-col>
      </a-row>
    </Main>
    <AddProductModal
      :saleId="SaleId"
      :productsIds="productsIds"
      @hideAddProductModal="hideAddProductModal"
      @SelectedProduct="AddSelectedProduct"
      v-if="showAddProduct && SaleId"
    ></AddProductModal>
  </div>
</template>

<script>
import {
  FormValidationWrap,
  HorizontalFormStyleWrap,
} from "../forms/overview/Style";
import { RecordViewWrapper } from "../../view/crud/axios/style";
import { Main, TableWrapper } from "../styled";
import { useStore } from "vuex";
import { notification } from "ant-design-vue";
import { useRouter } from "vue-router";
import usePartnerSearch from "../../composable/usePartnerSearch";
import { useProduct } from "@/composable/useProduct";

import { DataService } from "@/config/dataService/dataService";
var FileSaver = require("file-saver");

import {
  computed,
  onMounted,
  reactive,
  ref,
  watchEffect,
  defineAsyncComponent,
  nextTick,
} from "vue";
import { useRoute, onBeforeRouteLeave } from "vue-router";
import moment from "moment";

const AddProductModal = defineAsyncComponent(() =>
  import("@/view/product/AddProductModal")
);
const dateFormat = "YYYY/MM/DD";
const todayDate = moment();

const columns = [
  {
    title: "Description",
    dataIndex: "description",
    width: "20%",
  },
  {
    title: "Condition",
    dataIndex: "condition",
    width: "20%",
  },
  {
    title: "Unit Price",
    dataIndex: "price",
    width: "20%",
    slots: {
      customRender: "price",
    },
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    width: "20%",
    slots: {
      customRender: "quantity",
    },
  },
  {
    title: "Total Price",
    dataIndex: "total",
    width: "15%",

    slots: {
      customRender: "total",
    },
  },
  {
    title: "Operation",
    dataIndex: "operation",
    slots: {
      customRender: "operation",
    },
  },
];
const data = [];

const Sale = {
  name: "Sale",
  components: {
    RecordViewWrapper,
    Main,
    TableWrapper,
    FormValidationWrap,
    HorizontalFormStyleWrap,
    AddProductModal,
  },
  setup() {
    const { formatProductToList } = useProduct();

    const { state, dispatch } = useStore();
    const { params } = useRoute();
    const partnerApi = computed(() => state.partnerApi.data);
    const partnerApiLoading = computed(() => state.partnerApi.loading);
    const SaleApi = computed(() => state.saleApi);
    const saleNumber = computed(() => state.saleApi.saleNumber);
    const SaleApiloading = computed(() => state.saleApi.loading);
    const ProductApi = computed(() => state.ProductApi.data);
    const isSaleProductLoading = computed(() => state.productSaleApi.loading);
    const productSaleApi = computed(() => state.productSaleApi.data);
    const ProductAreLoading = computed(() => state.ProductApi.loading);
    const selectedProducts = ref(data);
    const router = useRouter();

    const editableData = reactive({});
    const saleLabel = computed(() => {
      if (params.type) {
        switch (params.type) {
          case "1":
            return (saleLabel.value = "Order");
          case "2":
            return (saleLabel.value = "Invoice");

          default:
            return (saleLabel.value = "Quotation");
        }
      }
      return "";
    });
    var currentSaleNumber = ref("");
    var formState = reactive({
      layout: "vertical",
      partenaireId: null,
      number: null,
      saleType: 1,
      saleStatus: 1,
      salesDate: todayDate,
      note: "",
      privateNote: "",
    });

    const formRef = ref();
    const showAddProduct = ref(false);

    const rules = {
      salesDate: [
        {
          type: "object",
          required: true,
          message: "This field is required",
          trigger: "change",
        },
      ],
      number: [
        {
          required: true,
          message: "This field is required",
          trigger: "change",
        },
      ],
      partenaireId: [
        {
          required: true,
          message: "This field is required",
          trigger: "change",
        },
      ],
    };

    onMounted(() => {
      dispatch("emptySale");
      nextTick();
      if (params.id) {
        dispatch("singleSaleGetData", params.id);
      } else {
        const generateData = {
          saleType: params.type,
          Year: moment().year(),
        };
        dispatch("saleGenerateNumber", generateData);
      }
      dispatch("partnerInfo");
    });
    watchEffect(() => {
      if (SaleApi.value && SaleApi.value.saleId) {
        formState.id = SaleApi.value.data.id;
        formState.salesDate = moment(SaleApi.value.data.salesDate);
        formState.number = SaleApi.value.data.number;
        formState.saleStatus = SaleApi.value.data.saleStatus;
        formState.partenaireId = SaleApi.value.data.partenaireId;
        formState.note = SaleApi.value.data.note;
        formState.privateNote = SaleApi.value.data.privateNote;
      }

      if (saleNumber.value) {
        formState.number = saleNumber.value;
      }
    });

    const productsData = computed(() =>
      productSaleApi.value && productSaleApi.value.length
        ? productSaleApi.value.map((product, key) => {
            const {
              productId,
              quantity,
              description,
              condition,
              quantityInStock,
              quantityInitial,
              price,
              saleId,
            } = product;
            return {
              key: key + 1,
              id: productId,
              description,
              condition,
              quantityInStock,
              quantityInitial,
              saleId: saleId,
              quantity,
              price,
              product,
            };
          })
        : []
    );
    const productsIds = computed(() =>
      productSaleApi.value && productSaleApi.value.length
        ? productSaleApi.value.map((product) => product.productId)
        : []
    );

    const partnerList = computed(() => {
      return partnerApi.value;
    });
    const SaleId = computed(() => {
      if (SaleApi.value) {
        return SaleApi.value.saleId;
      }
      return null;
    });

    const onSubmit = () => {
      formRef.value
        .validate()
        .then(async () => {
          formState.Status = formState.saleStatus;
          formState.saleType = Number(params.type);
          formState.products = productsData.value;

          if (!SaleId.value) {
            dispatch("saleSubmitData", { formState });
          } else {
            delete formState.products;

            dispatch("saleUpdateData", { formState });
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    };
    const onChangeSaleType = async (type) => {
      const changeSaleType = {
        saleId: SaleId.value,
        saleType: type,
      };
      try {
        const response = await DataService.post(
          "api/sale/ChangeSaleType",
          changeSaleType
        );
        const newSale = response.data.data;
        router.replace(
          "/sale/edit-sale/" + newSale.saleType + "/" + newSale.id
        );
        notification.success({
          message: "Saved successfully",
        });
      } catch (err) {
        notification.error({
          message: err,
        });
      }
    };
    const generatePdf = async () => {
      try {
        const response = await DataService.getFile(
          "api/sale/GeneratePDF?SaleId=" + SaleId.value
        );

        if (response) {
          var byteCharacters = response.data;
          var blob = new Blob([byteCharacters], { type: "application/pdf" });
          FileSaver.saveAs(blob, saleLabel.value + "-" + formState.number);
        }
      } catch (err) {
        console.log("error", err);
        notification.error({
          message: "Error while downloading PDF",
        });
      }
    };

    // #region Product
    const showAddProductModal = () => {
      showAddProduct.value = true;
    };
    const hideAddProductModal = (value) => {
      showAddProduct.value = value;
    };
    const AddSelectedProduct = (product) => {
      let productToAdd = {
        productId: product.id,
        saleId: SaleId.value,
        unitPrice: product.priceForSales,
        quantity: 0,
      };
      if (SaleId.value) {
        dispatch("productSaleSubmitData", { productToAdd });
      } else {
        productSaleApi.value.push(formatProductToList(product));
      }
    };
    // #endregion
    // #region table
    const edit = (key) => {
      editableData[key] = {
        ...productsData.value.filter((item) => key === item.key)[0],
      };
    };

    const save = (key) => {
      let editableobject = productsData.value.filter(
        (item) => key === item.key
      )[0];

      let isGreaterThanStock =
        editableData[key].quantity > editableobject.quantityInitial;
      if (!isGreaterThanStock) {
        Object.assign(editableobject, editableData[key]);
        if (SaleId.value) {
          dispatch("productSaleUpdateData", editableobject);
        }
        delete editableData[key];
      } else {
        notification.error({
          message: "Quantity cannot be greater than stock",
        });
      }
    };

    const deleteFromSale = (product) => {
      const confirm = window.confirm("Are you sure delete this?");
      if (confirm) {
        dispatch("productSaleDelte", product);
      }
      return false;
    };
    const cancel = (key) => {
      delete editableData[key];
    };

    // #endregion

    onBeforeRouteLeave(() => {
      // dispatch('emptySale');
      // onSubmit();
    });

    const { partnerData, searchPartner } = usePartnerSearch(
      formState.partenaireId
    );
    return {
      partnerApiLoading,
      partnerList,
      partnerApi,
      partnerData,
      searchPartner,
      formRef,
      rules,
      onSubmit,
      columns,
      formState,
      SaleApi,
      SaleId,
      ProductApi,
      productsData,
      ProductAreLoading,
      SaleApiloading,
      dateFormat,
      showAddProductModal,
      showAddProduct,
      hideAddProductModal,
      selectedProducts,
      AddSelectedProduct,
      editableData,
      editingKey: "",
      edit,
      save,
      deleteFromSale,
      cancel,
      isSaleProductLoading,
      productSaleApi,
      productsIds,
      saleLabel,
      currentSaleNumber,
      params,
      onChangeSaleType,
      generatePdf,
    };
  },
};

export default Sale;
</script>
<style lang="scss">
#app {
  .ant-upload {
    width: 100%;
  }
  .custom,
  .ant-calendar-picker-input.ant-input {
    height: 38px;
  }
  .button-container {
    align-self: center;
    .ant-btn {
      height: 38px;
      width: 100%;
    }
  }
  .editable-row-operations a {
    margin-right: 8px;
  }
}
</style>
